<template>
  <div>
    <v-btn
      @click="
        isMain
          ? $router.push({ name: 'home' })
          : checkBack
          ? $router.push({ name: 'mobile_attraction' })
          : $router.push({ name: 'mobile_citypass' })
      "
      color="black"
      icon
      x-large
    >
      <v-row no-gutters justify="center" align="center">
        <v-col class="col-12 pa-0">
          <!-- <v-icon x-large style="height: 100%; width: auto"
            >mdi-arrow-left
          </v-icon> -->
          <img src="@/assets/mobileBackButton.svg" />
        </v-col>
        <!-- <v-col class="col-12 pa-0" style="font-size: 12px; line-height: 12px"
          >back</v-col
        > -->
      </v-row>
    </v-btn>
    <attraction-detail-page />
  </div>
</template>

<script>
import AttractionDetailPage from "@/components/OurAttractions/AttractionDetailPage.vue";
export default {
  components: {
    AttractionDetailPage,
  },
  data: () => ({
    checkBack: false,
    isMain: false,
  }),
  mounted() {
    if (localStorage.selectedCitypass) {
      this.checkBack = true;
    }
    this.isMain = this.$route.query.isMain;
  },
};
</script>