<template>
  <v-container class="mobile-container">
    <mobile-attraction-details />
  </v-container>
</template>

<script>
import MobileAttractionDetails from "@/components/mobile/attractionDetails/AttractionDetails.vue";
export default {
  components: {
    MobileAttractionDetails,
  },
};
</script>

<style scoped>
.mobile-container {
    max-width: 600px !important
}
</style>